import type { PropsWithChildren } from "react";

import { Paper } from "@stacklet/ui";
import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";

import { BreadCrumbsHeader } from "app/components/BreadCrumbsHeader";
import { Page } from "app/components/layout/Page";

import type { PageWithBreadCrumbs_query$key } from "./__generated__/PageWithBreadCrumbs_query.graphql";
import type { BreadCrumbElement } from "app/components/BreadCrumbsHeader";

export interface PageWithBreadCrumbsProps extends PropsWithChildren {
  title: string;
  queryRef: PageWithBreadCrumbs_query$key;
  breadcrumbs?: BreadCrumbElement[];
  defaultTitle?: string;
  withPaper?: boolean;
}

export function PageWithBreadCrumbs({
  title,
  queryRef,
  defaultTitle,
  breadcrumbs,
  children,
  withPaper = false,
}: PageWithBreadCrumbsProps) {
  const data = useFragment(
    graphql`
      fragment PageWithBreadCrumbs_query on Query {
        ...BreadCrumbsHeader_query
      }
    `,
    queryRef,
  );

  return (
    <Page>
      <Helmet>
        <title>{defaultTitle ? `${defaultTitle} | ${title}` : title}</title>
      </Helmet>
      {breadcrumbs?.length ? (
        <BreadCrumbsHeader breadcrumbs={breadcrumbs} queryRef={data} />
      ) : null}

      {withPaper ? <Paper>{children}</Paper> : <>{children}</>}
    </Page>
  );
}
