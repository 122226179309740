/**
 * @generated SignedSource<<310266a0ea19ff44c0b99eb8d8f46579>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingWizardRootQuery$variables = Record<PropertyKey, never>;
export type OnboardingWizardRootQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingPage_query">;
};
export type OnboardingWizardRootQuery = {
  response: OnboardingWizardRootQuery$data;
  variables: OnboardingWizardRootQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingWizardRootQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OnboardingPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingWizardRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5a771916cf56eb9a9f68fc6aeb9bb4ba",
    "id": null,
    "metadata": {},
    "name": "OnboardingWizardRootQuery",
    "operationKind": "query",
    "text": "query OnboardingWizardRootQuery {\n  ...OnboardingPage_query\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment OnboardingPage_query on Query {\n  ...PageWithBreadCrumbs_query\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n"
  }
};

(node as any).hash = "9c0b2d06fbfae604b06ebaffd38737da";

export default node;
