import { useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";

import { PreferencesDialog } from "app/components/dialogs";
import { useConfig } from "app/contexts";
import { useUserWithIdentity } from "app/hooks";

interface Props {
  collapsed?: boolean;
}

export function NavBarUserMenu({ collapsed = false }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] =
    useState<boolean>(false);

  const config = useConfig();
  const user = useUserWithIdentity();

  const username =
    user.attributes?.name ||
    user.attributes?.email ||
    user.identity?.userId ||
    user.username;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const openPreferenceDialog = (value: boolean) => {
    setIsPreferencesDialogOpen(value);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        sx={{
          "&.MuiButton-root": {
            padding: 0,
            width: "100%",
            display: "flex",
            height: "unset",
          },
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          sx={{
            justifyContent: collapsed ? "center" : undefined,
            width: "100%",
          }}
        >
          <AccountCircleIcon
            sx={{ fontSize: (theme) => theme.typography.pxToRem(16) }}
          />
          {!collapsed ? (
            <Box
              sx={{
                ml: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {username}
            </Box>
          ) : null}
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="account-menu"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        keepMounted
      >
        <MenuItem
          sx={{
            "&.Mui-disabled": {
              opacity: 1,
              borderBottom: `1px solid ${grey[300]}`,
            },
          }}
          disabled
        >
          Signed in as {username}
        </MenuItem>
        {config?.analytics_configurable ? (
          <MenuItem
            onClick={(e) => {
              e.nativeEvent.stopPropagation();
              setIsPreferencesDialogOpen(true);
            }}
          >
            Preferences
          </MenuItem>
        ) : null}
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
      <PreferencesDialog
        isDialogOpen={isPreferencesDialogOpen}
        openDialog={openPreferenceDialog}
      />
    </div>
  );
}
