/**
 * @generated SignedSource<<c79bf156954e64baaea161b2275c0b09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeployBindingMutation$variables = {
  uuid: string;
};
export type DeployBindingMutation$data = {
  readonly deployBinding: {
    readonly binding: {
      readonly deployment: {
        readonly percentageDeployed: number;
      } | null | undefined;
      readonly lastDeployed: string | null | undefined;
      readonly name: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type DeployBindingMutation = {
  response: DeployBindingMutation$data;
  variables: DeployBindingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastDeployed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BindingDeployment",
  "kind": "LinkedField",
  "name": "deployment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentageDeployed",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeployBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "deployBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeployBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "deployBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0aaa9b34f2538980463b0e20fa9a9edb",
    "id": null,
    "metadata": {},
    "name": "DeployBindingMutation",
    "operationKind": "mutation",
    "text": "mutation DeployBindingMutation(\n  $uuid: String!\n) {\n  deployBinding(uuid: $uuid) {\n    binding {\n      uuid\n      name\n      lastDeployed\n      deployment {\n        percentageDeployed\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5eb284c6dfe65942eeb70b5f8a352a2";

export default node;
