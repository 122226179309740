import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Paper } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { Link } from "app/components";
import DetailsMetaData from "app/components/DetailsMetaData";
import DetailsGridItem from "app/components/grid/DetailsGridItem";
import { StatusIcon } from "app/components/icons/StatusIcon";
import { SourceView } from "app/components/SourceView/SourceView";
import CircularSuspense from "app/components/suspense/CircularSuspense";
import { Tab } from "app/components/Tab";
import { useTabsRef } from "app/hooks";
import { computeExecutionDetails } from "app/utils/computeExecutionDetails";

import { OverviewPage } from "../../OverviewPage";
import { ExecutionLog } from "./components/ExecutionLog";
import { ExecutionResources } from "./components/ExecutionResources";

import type { ExecutionDetailsRootQuery } from "./__generated__/ExecutionDetailsRootQuery.graphql";

const ResourceTableWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0, 0, 0),
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export function ExecutionDetailsRoot() {
  const params = useParams<"executionId">();
  const executionId = params.executionId ?? "";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  const tabsRef = useTabsRef();

  const view = searchParams.get("view");
  const tabValue = view || "resources";

  const data = useLazyLoadQuery<ExecutionDetailsRootQuery>(
    graphql`
      query ExecutionDetailsRootQuery($uuid: String!) {
        ...OverviewPage_query
        execution(uuid: $uuid) {
          event
          uuid
          start
          end
          paramRegion
          metricResources
          metricApiCalls
          metricException
          paramDryrun
          status
          issues
          policy {
            name
            uuid
            version
          }
          account {
            key
            provider
          }
          ...ExecutionLog_log
        }
      }
    `,
    {
      uuid: executionId,
    },
  );

  const execution = data.execution;
  const { timeStampInUTC, executionTime, avgApiRate } = computeExecutionDetails(
    execution?.start,
    execution?.end || null,
    execution?.metricApiCalls,
  );

  return (
    <OverviewPage queryRef={data} title="Execution Details">
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Box my={4}>
            <Typography
              color="textSecondary"
              sx={{ textTransform: "uppercase" }}
              variant="subtitle1"
              gutterBottom
            >
              execution details
              {execution?.issues && execution.issues.length > 0 ? (
                <StatusIcon data-testid="error-icon" status="error" />
              ) : null}
            </Typography>
            <Typography variant="h6">{execution?.uuid}</Typography>
          </Box>
          <DetailsMetaData>
            <Grid alignItems="center" direction="row" spacing={8} container>
              <DetailsGridItem
                direction="column"
                title="POLICY NAME"
                value={
                  <Link
                    to={`/policies/${execution?.policy.uuid}/version/${execution?.policy.version}`}
                  >
                    {execution?.policy.name}
                  </Link>
                }
              />
              <DetailsGridItem
                direction="column"
                title="PROVIDER"
                value={execution?.account.provider}
              />
              <DetailsGridItem
                direction="column"
                title="ACCOUNT ID"
                value={execution?.account.key}
              />
              <DetailsGridItem
                direction="column"
                title="REGION"
                value={execution?.paramRegion}
              />
              <DetailsGridItem
                direction="column"
                title="TIMESTAMP"
                value={timeStampInUTC}
              />
              <DetailsGridItem
                direction="column"
                title="EXECUTION TIME"
                value={executionTime}
              />
              <DetailsGridItem
                direction="column"
                title="RESOURCES"
                value={execution?.metricResources}
              />
              <DetailsGridItem
                direction="column"
                title="API CALLS"
                value={execution?.metricApiCalls}
              />
              <DetailsGridItem
                direction="column"
                title="AVG API RATE"
                value={avgApiRate}
              />
              <DetailsGridItem
                direction="column"
                title="AVG RESOURCE/CALL"
                value={execution?.metricResources}
              />

              <DetailsGridItem
                direction="column"
                title="IS DRY RUN"
                value={execution?.paramDryrun}
              />
              <DetailsGridItem
                direction="column"
                title="RAISED EXCEPTION"
                value={execution?.metricException ? "Yes" : "No"}
              />
              <DetailsGridItem
                direction="column"
                title="STATUS"
                value={execution?.status}
              />
            </Grid>
          </DetailsMetaData>
          <SourceView
            lang="json"
            source={execution?.event ?? ""}
            title="Event"
          />
        </div>

        {execution?.issues && execution.issues.length > 0 ? (
          <ResourceTableWrapper>
            <Typography color="textSecondary" variant="subtitle1" gutterBottom>
              EXECUTION ERRORS
            </Typography>

            <TableContainer component={Paper} data-testid="issues-table">
              <Table aria-label="Issues" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Issue Messages:</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {execution.issues.map((issue, i) => (
                    <TableRow key={i}>
                      <TableCell>{issue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ResourceTableWrapper>
        ) : null}
        <Box mt={3}>
          <Box>
            <Tabs
              action={tabsRef}
              aria-label="Execution Detail tabs"
              indicatorColor="primary"
              value={tabValue}
            >
              <Tab
                label="Resources"
                onClick={() => navigate("?view=resources")}
                value="resources"
              />
              <Tab
                data-testid="execution-log"
                label="Log"
                onClick={() => navigate("?view=log")}
                value="log"
              />
            </Tabs>
          </Box>
          <CircularSuspense>
            {!view || view === "resources" ? (
              <ExecutionResources executionId={executionId} />
            ) : null}
            {view === "log" && execution ? (
              <ExecutionLog execution={execution} />
            ) : null}
          </CircularSuspense>
        </Box>
      </Box>
    </OverviewPage>
  );
}
