import { useMemo } from "react";

import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay";

import { formatProvider } from "app/utils/formatProvider";

import type { useEnabledDashboardsQuery } from "./__generated__/useEnabledDashboardsQuery.graphql";
import type { FilterBooleanOperator } from "app/contexts/__generated__/AnalyticsContextQuery.graphql";
import type { Provider } from "app/contexts/ProviderContext";

function addPreviewCostDashboards(
  dashboards: {
    label: string;
    id: string;
    key: string;
    target: string;
  }[],
) {
  return dashboards.flatMap((dashboard) => {
    if (dashboard.key.includes("cost")) {
      const targetParts = dashboard.target.split("/");
      return [
        dashboard,
        {
          ...dashboard,
          id: `${dashboard.id}-preview`,
          label: `${dashboard.label} (preview)`,
          target: `/${targetParts[1]}/preview/${targetParts[2]}`,
        },
      ];
    }
    return [dashboard];
  });
}

export function useEnabledDashboards(provider: Provider) {
  const filterElement = useMemo(() => {
    const providerFilter = {
      single: {
        name: "provider",
        value: formatProvider(provider),
      },
    };
    const systemFilter = {
      single: { name: "system", value: "true" },
    };

    return {
      multiple: {
        operands: [providerFilter, systemFilter],
        operator: "AND" as FilterBooleanOperator,
      },
    };
  }, [provider]);

  // TODO: should be a fragment
  const data = useLazyLoadQuery<useEnabledDashboardsQuery>(
    graphql`
      query useEnabledDashboardsQuery($filterElement: FilterElementInput) {
        dashboards(filterElement: $filterElement) {
          edges {
            node {
              key
              title
            }
          }
        }
      }
    `,
    { filterElement },
  );

  const enabledDashboards = (data.dashboards.edges ?? []).map((edge) => {
    const { title, key } = edge.node;
    return {
      label: title,
      id: title.toLocaleLowerCase(),
      key: key,
      target: `/dashboards/${key}`,
    };
  });

  return addPreviewCostDashboards(enabledDashboards);
}
