import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";

import { useStickyState } from "app/hooks";
import { DEFAULT_PAGE_SIZE } from "app/utils/consts";

import ResourcesRawJson from "./ResourcesRawJson";
import ResourcesTable from "./ResourcesTable";

import type {
  ExecutionResources_ExecutionQuery,
  ExecutionResources_ExecutionQuery$data,
} from "./__generated__/ExecutionResources_ExecutionQuery.graphql";

type ResourcesView = "table" | "json";

interface Props {
  executionId: string;
}

export function ExecutionResources({ executionId }: Props) {
  const [resourcesview, setResourcesView] = useStickyState<ResourcesView>(
    "table",
    "resources-view",
  );

  const executionByIdQuery = graphql`
    query ExecutionResources_ExecutionQuery(
      $uuid: String!
      $first: Int
      $last: Int
      $after: String
      $before: String
    ) {
      execution(uuid: $uuid) {
        ...ResourcesTable_execution
          @arguments(first: $first, last: $last, before: $before, after: $after)
        ...ResourcesRawJson_execution
          @arguments(first: $first, last: $last, before: $before, after: $after)
      }
    }
  `;

  // TODO: should be a fragment
  const data = useLazyLoadQuery<ExecutionResources_ExecutionQuery>(
    executionByIdQuery,
    {
      uuid: executionId,
      first: DEFAULT_PAGE_SIZE,
    },
  );

  const handleView = (
    e: React.MouseEvent<HTMLElement>,
    newView: ResourcesView,
  ) => {
    setResourcesView(newView ?? resourcesview);
  };

  type Execution = ExecutionResources_ExecutionQuery$data["execution"];

  function ResourceDatagrid({
    execution,
    table = true,
  }: {
    execution: Execution;
    table: boolean;
  }) {
    if (execution) {
      return table ? (
        <ResourcesTable execution={execution} />
      ) : (
        <ResourcesRawJson execution={execution} />
      );
    }
    return null;
  }

  return (
    <Box mt={1} p={1}>
      <ToggleButtonGroup
        onChange={handleView}
        size="small"
        value={resourcesview}
        exclusive
      >
        <ToggleButton
          aria-label="table"
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(12),
            textTransform: "none",
          }}
          value="table"
        >
          Table
        </ToggleButton>
        <ToggleButton
          aria-label="json"
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(12),
            textTransform: "none",
          }}
          value="json"
        >
          JSON
        </ToggleButton>
      </ToggleButtonGroup>

      <ResourceDatagrid
        execution={data.execution}
        table={resourcesview === "table"}
      />
    </Box>
  );
}
