import type { PropsWithChildren } from "react";

import Typography from "@mui/material/Typography";
import { Paper } from "@stacklet/ui";
import { Helmet } from "react-helmet-async";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BreadCrumbsHeader } from "app/components/BreadCrumbsHeader";
import { Page } from "app/components/layout/Page";

import type { NavPage_query$key } from "./__generated__/NavPage_query.graphql";

type Breadcrumb =
  | {
      label: string;
      tabs?: undefined;
    }
  | {
      tabs: {
        label: string;
        target: string;
      }[];
      label?: undefined;
    };

interface Props extends PropsWithChildren {
  queryRef: NavPage_query$key;
  breadcrumbs: Breadcrumb[];
  title: string;
  heading: string;
  isForm?: boolean;
  withPaper?: boolean;
}

export function NavPage({
  queryRef,
  breadcrumbs,
  children,
  title,
  heading,
  isForm = false,
  withPaper = true,
}: Props) {
  const data = useFragment(
    graphql`
      fragment NavPage_query on Query {
        ...BreadCrumbsHeader_query
      }
    `,
    queryRef,
  );

  return (
    <Page>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BreadCrumbsHeader breadcrumbs={breadcrumbs} queryRef={data} />
      {withPaper ? (
        <Paper isForm={isForm}>
          <Typography sx={{ mb: 2 }} variant="subtitle1">
            {heading}
          </Typography>
          {children}
        </Paper>
      ) : (
        <>{children}</>
      )}
    </Page>
  );
}
