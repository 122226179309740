import { Tooltip } from "@stacklet/ui";
import { formatDistanceToNow } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { DATETIME_FORMAT_STR } from "app/utils/consts";

interface Props {
  timestamp?: string | null;
  additional?: string;
}

export function HumanTimestamp({ timestamp, additional }: Props) {
  if (!timestamp) {
    return <span>--</span>;
  }

  let formattedTimestamp;
  try {
    formattedTimestamp = formatInTimeZone(
      timestamp,
      "UTC",
      DATETIME_FORMAT_STR,
    );
  } catch {
    console.error("unable to format timestamp", timestamp);
    return <span>--</span>;
  }

  const title = (
    <div>
      {`${formattedTimestamp} UTC`}
      <br />
      {timestamp}
      {additional ? (
        <div>
          <br />
          {additional}
        </div>
      ) : null}
    </div>
  );

  return (
    <Tooltip title={title}>
      <span>
        {formatDistanceToNow(new Date(timestamp), {
          addSuffix: true,
        })}
      </span>
    </Tooltip>
  );
}
