import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Button, Paper, Tooltip } from "@stacklet/ui";
import CheckMark from "assets/images/green_check_mark.svg?react";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import DetailsMetaData from "app/components/DetailsMetaData";
import NoData from "app/components/empty-state/NoData";
import DetailsGridItem from "app/components/grid/DetailsGridItem";
import { Tab } from "app/components/Tab";
import { useTabsRef } from "app/hooks";
import { DEFAULT_PAGE_SIZE } from "app/utils/consts";
import { ReadOnlyPage } from "app/views/read-only/components/ReadOnlyPage";

import { AdminPage } from "../../AdminPage";
import RemovePolicyCollection from "./actions/RemovePolicyCollection";
import { PolicyCollectionBindings } from "./PolicyCollectionBindings";
import { PolicyCollectionPolicies } from "./PolicyCollectionPolicies";

import type { PolicyCollectionDetailsQuery } from "./__generated__/PolicyCollectionDetailsQuery.graphql";

const ActionButtonWrapper = styled("div")(({ theme }) => ({
  "&+.actionButtonWrapper": {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  showActions?: boolean;
}

export function PolicyCollectionDetails({ showActions = true }: Props) {
  const params = useParams<"collectionId">();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const tabsRef = useTabsRef();

  const view = searchParams.get("view");
  const tabValue = view || "policies";

  const collectionId = params.collectionId ?? "";

  const data = useLazyLoadQuery<PolicyCollectionDetailsQuery>(
    graphql`
      query PolicyCollectionDetailsQuery(
        $uuid: String
        $first: Int
        $after: String
      ) {
        ...AdminPage_query
        ...ReadOnlyPage_query
        policyCollection(uuid: $uuid) {
          ...PolicyCollectionBindings_bindings
          ...PolicyCollectionPolicies_policyMappings
            @arguments(first: $first, after: $after)
          policyMappings(first: $first, after: $after) {
            pageInfo {
              total
              __id
            }
          }
          uuid
          name
          description
          provider
          autoUpdate
          system
        }
      }
    `,
    {
      uuid: collectionId,
      first: DEFAULT_PAGE_SIZE,
    },
  );

  const pageInfoId = data.policyCollection?.policyMappings.pageInfo.__id;

  if (!data.policyCollection) {
    return (
      <NoData message={`Policy collection ${collectionId} can not be found.`} />
    );
  }
  const Page = showActions ? AdminPage : ReadOnlyPage;

  return (
    <Page
      queryRef={data}
      title={showActions ? "Policy Collection Details" : "Policy Collections"}
      withPaper={false}
    >
      <Paper>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            {data.policyCollection.name}
          </Typography>
          {showActions && !data.policyCollection.system ? (
            <Box data-testid="action-button-wrapper" display="flex">
              <ActionButtonWrapper className="actionButtonWrapper">
                <Button
                  buttonType={"tertiary"}
                  onClick={() =>
                    navigate(
                      `/admin/bindings/create?policyCollectionUUID=${encodeURIComponent(
                        data.policyCollection?.uuid || "",
                      )}&policyCollectionName=${encodeURIComponent(
                        data.policyCollection?.name || "",
                      )}`,
                    )
                  }
                >
                  Create binding
                </Button>
              </ActionButtonWrapper>
              <ActionButtonWrapper className="actionButtonWrapper">
                <Button
                  buttonType="outline-main"
                  onClick={() => navigate("edit")}
                >
                  Edit
                </Button>
                <ActionButtonWrapper />
              </ActionButtonWrapper>
              <ActionButtonWrapper className="actionButtonWrapper">
                <RemovePolicyCollection
                  policyCollectionUuid={data.policyCollection.uuid}
                />
              </ActionButtonWrapper>
            </Box>
          ) : null}
        </Box>
        <DetailsMetaData>
          <Grid alignItems="center" direction="row" spacing={8} container>
            <DetailsGridItem
              direction="column"
              title="PROVIDER"
              value={data.policyCollection.provider}
            />
            <DetailsGridItem
              direction="column"
              title="DESCRIPTION"
              value={data.policyCollection.description}
            />
            <DetailsGridItem
              direction="column"
              title="# OF POLICIES"
              value={data.policyCollection.policyMappings.pageInfo.total || 0}
            />
            {data.policyCollection.system ? (
              <DetailsGridItem direction="column" title="system">
                <Tooltip title="System policy collections are used internally by the Stacklet Platform">
                  <span>
                    <CheckMark />
                  </span>
                </Tooltip>
              </DetailsGridItem>
            ) : null}
            {data.policyCollection.autoUpdate ? (
              <DetailsGridItem direction="column" title="auto update">
                <Tooltip title="An auto updating policy collection will bump versions of the policies to latest as they are scanned.">
                  <span>
                    <CheckMark />
                  </span>
                </Tooltip>
              </DetailsGridItem>
            ) : null}
          </Grid>
        </DetailsMetaData>
      </Paper>

      <Tabs
        action={tabsRef}
        aria-label="Policy Collection Detail tabs"
        indicatorColor="primary"
        sx={{ mt: 3 }}
        value={tabValue}
      >
        <Tab
          label="Policies"
          onClick={() => navigate("?view=policies")}
          value="policies"
        />
        <Tab
          label="Bindings"
          onClick={() => navigate("?view=bindings")}
          value="bindings"
        />
      </Tabs>

      <Paper>
        <Box
          sx={{
            height: "80%",
            backgroundColor: (theme) => theme.palette.common.white,
            px: 2,
            py: 1,
          }}
        >
          {!view || view === "policies" ? (
            <PolicyCollectionPolicies
              pageInfoId={pageInfoId}
              policyCollection={data.policyCollection}
              showActions={showActions}
            />
          ) : null}
          {view === "bindings" ? (
            <PolicyCollectionBindings
              policyCollection={data.policyCollection}
            />
          ) : null}
        </Box>
      </Paper>
    </Page>
  );
}
