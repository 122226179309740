import type { PropsWithChildren } from "react";

import { graphql, useFragment } from "react-relay";

import { PageWithBreadCrumbs } from "app/components/layout/PageWithBreadCrumbs";

import type { ReadOnlyPage_query$key } from "./__generated__/ReadOnlyPage_query.graphql";

interface Props extends PropsWithChildren {
  title: string;
  queryRef: ReadOnlyPage_query$key;
}

export function ReadOnlyPage({ children, queryRef, title }: Props) {
  const data = useFragment(
    graphql`
      fragment ReadOnlyPage_query on Query {
        ...PageWithBreadCrumbs_query
      }
    `,
    queryRef,
  );

  const breadcrumbs = [{ label: title }];

  return (
    <PageWithBreadCrumbs
      breadcrumbs={breadcrumbs}
      queryRef={data}
      title={title}
    >
      {children}
    </PageWithBreadCrumbs>
  );
}
