import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify";
import { graphql } from "react-relay";
import { commitLocalUpdate } from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";

import { authCookieHandler, getAuthCookieOptions } from "app/utils/auth";
import getConfigs from "app/utils/configs";
import { createUrlConfig } from "app/utils/localState";
import { signInRedirectHandler } from "app/utils/redirection";

import RelayEnvironment from "./RelayEnvironment";

import type { HubCapsule } from "@aws-amplify/core";
import type { CookieOptions } from "app/utils/auth";
import type { Environment } from "relay-runtime";
import type {
  Primitive,
  Record,
} from "relay-runtime/lib/store/RelayStoreTypes";

interface CognitoConfig {
  region: Primitive | Primitive[];
  userPoolId: Primitive | Primitive[];
  userPoolWebClientId: Primitive | Primitive[];
  authenticationFlowType: Primitive | Primitive[];
  cognitoInstall?: Primitive | Primitive[];
  saml: Primitive | Primitive[];
}

type CognitoConfigRecord = Record<CognitoConfig> | null | undefined;

async function createCognitoConfig(environment: Environment) {
  const config = (await getConfigs()).cognito;

  commitLocalUpdate(environment, async (store) => {
    const __typename = "Cognito";

    const record = store.create(__typename, __typename);
    record.setValue(config.cognito_user_pool_client_id, "userPoolWebClientId");
    record.setValue(config.cognito_user_pool_id, "userPoolId");
    record.setValue(config.cognito_user_pool_region, "region");
    record.setValue(config.cognito_install, "cognitoInstall");
    record.setValue(JSON.stringify(config.saml), "saml");
    record.setValue("USER_SRP_AUTH", "authenticationFlowType");
    store.getRoot().setLinkedRecord(record, __typename);
  });

  // The graphql queries against local data require a ref to at least 1 server definition. It can be empty, so we use __typename
  const localDataQuery = graphql`
    query bootstrapCognitoQuery {
      platform {
        __typename
      }
      Cognito {
        __typename
      }
    }
  `;

  const request = getRequest(localDataQuery);
  const operation = createOperationDescriptor(request, {});
  environment.retain(operation);
  return;
}

type AmpConfig = {
  region: CognitoConfig;
  userPoolId: CognitoConfig;
  userPoolWebClientId: CognitoConfig;
  mandatorySignIn: boolean;
  authenticationFlowType: CognitoConfig;
  cookieStorage: CookieOptions;
  oauth?: {
    domain: CognitoConfig;
    redirectSignIn: string;
    redirectSignOut: string;
    scopes: string[];
  };
};

async function configureAmplify(environment: Environment) {
  async function setAmplify(config: Record<CognitoConfig>) {
    const ampConfig: AmpConfig = {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      mandatorySignIn: true,
      authenticationFlowType: config.authenticationFlowType,
      cookieStorage: getAuthCookieOptions(),
    };

    if (typeof config.saml === "string" && config.saml !== "{}") {
      ampConfig["oauth"] = {
        domain: config.cognitoInstall,
        redirectSignIn: window.origin,
        redirectSignOut: window.origin,
        scopes: ["email", "openid"],
      };
    }

    Hub.listen("auth", authHandler);

    Amplify.configure({
      Auth: ampConfig,
    });
  }

  const cognitoConfig: CognitoConfigRecord = environment
    .getStore()
    .getSource()
    ?.get("Cognito");

  if (cognitoConfig) await setAmplify(cognitoConfig);
}

async function authHandler(data: HubCapsule) {
  // auth cookie must be set before redirect happens
  await authCookieHandler();
  await signInRedirectHandler(data);
}

async function createDeploymentConfig(environment: Environment) {
  const config = (await getConfigs()).deployment;

  commitLocalUpdate(environment, async (store) => {
    const __typename = "Deployment";

    const record = store.create(__typename, __typename);
    record.setValue(config.gql_uri, "gqlUri");
    if (config.headers) {
      record.setValue(JSON.stringify(config.headers), "headers");
    }
    store.getRoot().setLinkedRecord(record, __typename);
  });

  // The graphql queries against local data require a ref to at least 1 server definition. It can be empty, so we use __typename
  const localDataQuery = graphql`
    query bootstrapDeploymentQuery {
      platform {
        __typename
      }
      Deployment {
        __typename
      }
    }
  `;

  const request = getRequest(localDataQuery);
  const operation = createOperationDescriptor(request, {});
  environment.retain(operation);
  return;
}

async function bootstrapStacklet(): Promise<void> {
  await createDeploymentConfig(RelayEnvironment);
  await createCognitoConfig(RelayEnvironment);
  await configureAmplify(RelayEnvironment);
  await createUrlConfig(RelayEnvironment);
}

export { bootstrapStacklet };

export type { CognitoConfigRecord };
