/**
 * @generated SignedSource<<0f34adb7cdfdd4b96b21bd73cd7296f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityQuery$variables = Record<PropertyKey, never>;
export type ActivityQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OverviewPage_query">;
};
export type ActivityQuery = {
  response: ActivityQuery$data;
  variables: ActivityQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OverviewPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "05a0d2dc11441e47888ad3e13e227e74",
    "id": null,
    "metadata": {},
    "name": "ActivityQuery",
    "operationKind": "query",
    "text": "query ActivityQuery {\n  ...OverviewPage_query\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment OverviewPage_query on Query {\n  ...PageWithBreadCrumbs_query\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n"
  }
};

(node as any).hash = "294b6a65e65011f272671361d6f2e3ae";

export default node;
