import type { PropsWithChildren } from "react";

import { useHasRequiredPermission } from "app/hooks";
import { ADMIN_PERMISSIONS } from "app/utils/consts";
import { NotFound } from "app/views";

export function AdminRoute({ children }: PropsWithChildren) {
  const hasPermission = useHasRequiredPermission();
  const isAdmin = hasPermission(ADMIN_PERMISSIONS);

  if (!isAdmin) {
    return <NotFound />;
  }

  return <>{children}</>;
}
