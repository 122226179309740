/**
 * @generated SignedSource<<53a4e0bf70f5b899bf0550ecf77b6c8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingPage_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageWithBreadCrumbs_query">;
  readonly " $fragmentType": "OnboardingPage_query";
};
export type OnboardingPage_query$key = {
  readonly " $data"?: OnboardingPage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingPage_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingPage_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageWithBreadCrumbs_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "236c946c65c9d14c218e9d03930f11e3";

export default node;
