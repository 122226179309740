import { SupportMenu, type SupportMenuDisplay } from "@stacklet/ui";
import { graphql, useFragment } from "react-relay";

import type { Support_whoami$key } from "./__generated__/Support_whoami.graphql";

interface Props {
  queryRef: Support_whoami$key;
  display?: SupportMenuDisplay;
}

export function Support({ queryRef, display = "link" }: Props) {
  const data = useFragment(
    graphql`
      fragment Support_whoami on Query {
        whoami {
          email
          displayName
        }
      }
    `,
    queryRef,
  );

  if (!data.whoami) {
    return null;
  }

  const { email, displayName } = data.whoami;

  return (
    <SupportMenu
      display={display}
      userEmail={email || ""}
      userName={displayName || email || ""}
    />
  );
}
