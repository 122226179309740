import type { PropsWithChildren } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

const Root = styled("div")({
  flexGrow: 1,
  padding: 16,
});

export function Page({ children }: PropsWithChildren) {
  return (
    <Root>
      <Box sx={{ mx: 0, my: 0 }}>
        <Outlet />
        {children}
      </Box>
    </Root>
  );
}
