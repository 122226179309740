/**
 * @generated SignedSource<<a020a625e1cbb66d34d44851a87b7ca0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminPage_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NavPage_query">;
  readonly " $fragmentType": "AdminPage_query";
};
export type AdminPage_query$key = {
  readonly " $data"?: AdminPage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminPage_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminPage_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavPage_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d5a7b7b689b278f99dd6eb9fb0cbe3cf";

export default node;
