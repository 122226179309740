import type { PropsWithChildren } from "react";

import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { NavPage } from "app/components/layout/NavPage";

import type { AdminPage_query$key } from "./__generated__/AdminPage_query.graphql";

export interface AdminPageProps extends PropsWithChildren {
  queryRef: AdminPage_query$key;
  title: string;
  isForm?: boolean;
  withPaper?: boolean;
}

export function AdminPage({
  children,
  title,
  isForm = false,
  withPaper = true,
  queryRef,
}: AdminPageProps) {
  const data = useFragment(
    graphql`
      fragment AdminPage_query on Query {
        ...NavPage_query
      }
    `,
    queryRef,
  );

  const breadcrumbs = [
    { label: "Admin" },
    {
      tabs: [
        { label: "Accounts", target: `/admin/accounts` },
        { label: "Account Groups", target: `/admin/account-groups` },
        { label: "Bindings", target: `/admin/bindings` },
        { label: "Policies", target: `/admin/policies` },
        {
          label: "Policy Collections",
          target: `/admin/policy-collections`,
        },
      ],
    },
  ];

  return (
    <NavPage
      breadcrumbs={breadcrumbs}
      heading={title}
      isForm={isForm}
      queryRef={data}
      title={`Admin | ${title}`}
      withPaper={withPaper}
    >
      {children}
    </NavPage>
  );
}
